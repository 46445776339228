@font-face {
  font-family: Courgette;
  src: url(/assets/fonts/Courgette-Regular.ttf);
}

@font-face {
  font-family: CenturyGothic;
  src: url(/assets/fonts/GOTHIC.woff);
}

@font-face {
  font-family: SourceCodePro;
  src: url(/assets/fonts/SourceCodePro-Regular.ttf);
}

.courgette-font {
  font-family: Courgette;
}

.century-gothic-font {
  font-family: CenturyGothic;
}

.source-code-pro-font {
  font-family: SourceCodePro;
}

.text-small {
  font-weight: 600;
  font-size: 1rem;
}

.text-regular {
  font-weight: 600;
  font-size: 1.5rem;
}

.text-big {
  font-weight: 600;
  font-size: 2rem;
}
