// http://ionicframework.com/docs/theming/
// App Global Sass
// ----------------------------------------------------------------------------
// Put style rules here that you want to apply globally. These styles are for
// the entire app and not just one component. Additionally, this file can be
// also used as an entry point to import other Sass files to be included in the
// output CSS.
//
// Shared Sass variables, which can be used to adjust Ionic's default Sass
// variables, belong in "theme/variables.scss".
//
// To declare rules for a specific mode, create a child rule for the .md, .ios,
// or .wp mode classes. The mode class is automatically applied to the <body>
// element in the app.
// @import '~@sonorus/ionic/scss/index';

// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';

@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import '../../../libs/scss/index';
