@import '../media-queries/media-queries.scss';

/*
--background	Background of the modal content
--border-color	Border color of the modal content
--border-radius	Border radius of the modal content
--border-style	Border style of the modal content
--border-width	Border width of the modal content
--height	Height of the modal
--max-height	Maximum height of the modal
--max-width	Maximum width of the modal
--min-height	Minimum height of the modal
--min-width	Minimum width of the modal
--width	Width of the modal
*/

.medium-modal {
  @include media('<=tablet', '<desktop') {
    --height: 80%;
    --width: 80%;
  }

  @include media('>=phone', '<tablet') {
    --height: 80%;
    --width: 80%;
  }

  @include media('>tablet') {
    --height: 50%;
    --width: 50%;
    --max-width: 600px;
  }
}

.big-modal {
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.32) !important;

  @include media('<=tablet', '<desktop') {
    --height: 80%;
    --width: 80%;
  }

  @include media('>=phone', '<tablet') {
    --height: 100%;
    --width: 100%;
  }

  @include media('>tablet') {
    --height: 75%;
    --width: 75%;
  }
}

.full-screen-modal {
  --width: 100%;
  --height: 100%;
}

.uber-modal {
  ion-backdrop {
    z-index: 30000;
  }

  div.modal-wrapper {
    z-index: 30001;
  }
}
