.menu__image-container {
  display: flex;
  align-items: center;
  justify-content: left;
}

.menu__logo {
  display: block;
  height: 24px;
}

.menu__logo-title {
  margin-top: 5px;
  height: 25px;
}
