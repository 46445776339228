.border-small {
  border-width: 15px;
  border-style: solid;
}

.border-large {
  border-width: 30px;
  border-style: solid;
}

.quickchat-widget {
  min-width: 150px;
  min-height: 150px;
}

.preview-widget {
  min-width: 200px;
  min-height: 200px;
}

.widget-button-container {
  width: 100%;
  height: 100%;
  // min-height: 150px;
  display: -webkit-flex;
  -webkit-flex-direction: column;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  .widget-title {
    text-align: center;
    align-self: center;
    z-index: 2;
    // padding: 5px 0px;
    max-height: 25%;
  }

  .widget-image {
    z-index: 1;
    max-width: 100%;
    // max-height: calc(100% - 25px);
    // object-fit: scale-down;
    max-height: 75%;
    flex: 0 1 auto;
  }

  /*
  .90-deg {
  }

  .180-deg {
  }
  */

  // .270deg {
  //   border: 1px solid #000000;
  //   transform: rotate(270deg);
  //   -ms-transform: rotate(270deg);
  //   -moz-transform: rotate(270deg);
  //   -webkit-transform: rotate(270deg);
  //   -o-transform: rotate(270deg);
  //   margin: calc((455px - 111px) / 2) calc((111px - 455px) / 2);
  // }

  .action-buttons {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 3;
  }
}
