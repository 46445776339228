/**
 * The following are shared across all platforms and apps
 */

// shared variables
@import 'variables';

// create/import other scss files or define as needed...
@import 'modals/modals.scss';
@import 'dropdowns/_dropdowns.scss';

@import 'menu.scss';
@import 'buttons.scss';
@import 'cursor.scss';
@import 'fonts.scss';

@import '../../../libs/features/ui/components/widget-button/widget-button.scss';

ion-thumbnail.culture-picker__country-flag {
  max-width: 24px;
  max-height: 24px;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

ion-fab {
  --ion-safe-area-right: 10px;
  padding-bottom: 10px;
}

table {
  width: 100%;
}

ion-grid.container {
  max-width: 1024px;
  margin: 0 auto;
}

ion-split-pane[contentId='main-content'] {
  &.split-pane-md.split-pane-visible > .split-pane-side {
    max-width: 300px !important;
  }

  ion-menu[menuId='mainMenu'] {
    ion-content {
      --background: var(--ion-color-primary);
      --ion-item-background: var(--ion-color-primary);
      --ion-item-color: var(--ion-color-primary-contrast);
      --ion-text-color-rgb: var(--ion-item-color);
    }
  }
}

// NEW STUFE

ion-content.sonorus-overview {
  --background: linear-gradient(151deg, rgba(255,255,255,1) 50%, rgba(61,96,172,0.4) 100%);
}

.gridster-row {
  border-top: 1px dashed #fff !important;
  border-bottom: 1px dashed #fff !important;
}

.gridster-column {
  border-left: 1px dashed #fff !important;
  border-right: 1px dashed #fff !important;
}

.item-divider {
  border-bottom: 1px solid var(--ion-color-primary) !important;
}

.privacy-policy-alert {
  --min-height: 35vh;
  --min-width: 70vw;
}
