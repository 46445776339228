// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/

:root {
  --ion-color-primary: #031634;
  --ion-color-primary-rgb: 3,22,52;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #03132e;
  --ion-color-primary-tint: #1c2d48;

  --ion-color-secondary: #033649;
  --ion-color-secondary-rgb: 3,54,73;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #033040;
  --ion-color-secondary-tint: #1c4a5b;

  --ion-color-tertiary: #036564;
  --ion-color-tertiary-rgb: 3,101,100;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #035958;
  --ion-color-tertiary-tint: #1c7474;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45,211,111;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255,196,9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235,68,90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-medium: #cdb380;
  --ion-color-medium-rgb: 205,179,128;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #b49e71;
  --ion-color-medium-tint: #d2bb8d;

  --ion-color-light: #e8ddcb;
  --ion-color-light-rgb: 232,221,203;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #ccc2b3;
  --ion-color-light-tint: #eae0d0;

}


//:root {
//	--ion-color-primary: #355497;
//	--ion-color-primary-rgb: 53,84,151;
//	--ion-color-primary-contrast: #ffffff;
//	--ion-color-primary-contrast-rgb: 255,255,255;
//	--ion-color-primary-shade: #2f4a85;
//	--ion-color-primary-tint: #4965a1;
//
//	--ion-color-secondary: #bbcaff;
//	--ion-color-secondary-rgb: 187,202,255;
//	--ion-color-secondary-contrast: #000000;
//	--ion-color-secondary-contrast-rgb: 0,0,0;
//	--ion-color-secondary-shade: #a5b2e0;
//	--ion-color-secondary-tint: #c2cfff;
//
//	--ion-color-tertiary: #1a3382;
//	--ion-color-tertiary-rgb: 26,51,130;
//	--ion-color-tertiary-contrast: #ffffff;
//	--ion-color-tertiary-contrast-rgb: 255,255,255;
//	--ion-color-tertiary-shade: #172d72;
//	--ion-color-tertiary-tint: #31478f;
//
//	--ion-color-success: #2dd36f;
//	--ion-color-success-rgb: 45,211,111;
//	--ion-color-success-contrast: #000000;
//	--ion-color-success-contrast-rgb: 0,0,0;
//	--ion-color-success-shade: #28ba62;
//	--ion-color-success-tint: #42d77d;
//
//	--ion-color-warning: #ffc409;
//	--ion-color-warning-rgb: 255,196,9;
//	--ion-color-warning-contrast: #000000;
//	--ion-color-warning-contrast-rgb: 0,0,0;
//	--ion-color-warning-shade: #e0ac08;
//	--ion-color-warning-tint: #ffca22;
//
//	--ion-color-danger: #eb445a;
//	--ion-color-danger-rgb: 235,68,90;
//	--ion-color-danger-contrast: #ffffff;
//	--ion-color-danger-contrast-rgb: 255,255,255;
//	--ion-color-danger-shade: #cf3c4f;
//	--ion-color-danger-tint: #ed576b;
//
//	--ion-color-medium: #92949c;
//	--ion-color-medium-rgb: 146,148,156;
//	--ion-color-medium-contrast: #000000;
//	--ion-color-medium-contrast-rgb: 0,0,0;
//	--ion-color-medium-shade: #808289;
//	--ion-color-medium-tint: #9d9fa6;
//
//	--ion-color-light: #f4f5f8;
//	--ion-color-light-rgb: 244,245,248;
//	--ion-color-light-contrast: #000000;
//	--ion-color-light-contrast-rgb: 0,0,0;
//	--ion-color-light-shade: #d7d8da;
//	--ion-color-light-tint: #f5f6f9;
//
//}

// :root {
//   --ion-color-primary: #355497;//#2e4a84;//#3d60ac;
//   --ion-color-primary-rgb: 61, 96, 172;
//   --ion-color-primary-contrast: #ffffff;
//   --ion-color-primary-contrast-rgb: 255, 255, 255;
//   --ion-color-primary-shade: #365497;
//   --ion-color-primary-tint: #5070b4;

//   --ion-color-secondary: #bbcaff;
//   --ion-color-secondary-rgb: 187, 202, 255;
//   --ion-color-secondary-contrast: #000000;
//   --ion-color-secondary-contrast-rgb: 0, 0, 0;
//   --ion-color-secondary-shade: #a5b2e0;
//   --ion-color-secondary-tint: #c2cfff;

//   --ion-color-tertiary: #1a3382;
//   --ion-color-tertiary-rgb: 26, 51, 130;
//   --ion-color-tertiary-contrast: #ffffff;
//   --ion-color-tertiary-contrast-rgb: 255, 255, 255;
//   --ion-color-tertiary-shade: #172d72;
//   --ion-color-tertiary-tint: #31478f;

//   --ion-color-success: #10dc60;
//   --ion-color-success-rgb: 16, 220, 96;
//   --ion-color-success-contrast: #ffffff;
//   --ion-color-success-contrast-rgb: 255, 255, 255;
//   --ion-color-success-shade: #0ec254;
//   --ion-color-success-tint: #28e070;

//   --ion-color-warning: #ffce00;
//   --ion-color-warning-rgb: 255, 206, 0;
//   --ion-color-warning-contrast: #ffffff;
//   --ion-color-warning-contrast-rgb: 255, 255, 255;
//   --ion-color-warning-shade: #e0b500;
//   --ion-color-warning-tint: #ffd31a;

//   --ion-color-danger: #f04141;
//   --ion-color-danger-rgb: 245, 61, 61;
//   --ion-color-danger-contrast: #ffffff;
//   --ion-color-danger-contrast-rgb: 255, 255, 255;
//   --ion-color-danger-shade: #d33939;
//   --ion-color-danger-tint: #f25454;

//   --ion-color-dark: #222428;
//   --ion-color-dark-rgb: 34, 34, 34;
//   --ion-color-dark-contrast: #ffffff;
//   --ion-color-dark-contrast-rgb: 255, 255, 255;
//   --ion-color-dark-shade: #1e2023;
//   --ion-color-dark-tint: #383a3e;

//   --ion-color-medium: #989aa2;
//   --ion-color-medium-rgb: 152, 154, 162;
//   --ion-color-medium-contrast: #ffffff;
//   --ion-color-medium-contrast-rgb: 255, 255, 255;
//   --ion-color-medium-shade: #86888f;
//   --ion-color-medium-tint: #a2a4ab;

//   --ion-color-light: #e8ecf5;
//   --ion-color-light-rgb: 232, 236, 245;
//   --ion-color-light-contrast: #000000;
//   --ion-color-light-contrast-rgb: 0, 0, 0;
//   --ion-color-light-shade: #ccd0d8;
//   --ion-color-light-tint: #eaeef6;
// }
